import React from 'react';
import Button from "react-bootstrap/Button";
import {Link} from "react-router-dom";
import {useAuth} from "../Contexts/AuthContext";

function Home() {
    const {currentUser, logout} = useAuth();

    return (
        <div>
            {currentUser ? (
                <Button variant="link" onClick={logout}>Logout</Button>
            ) : (
                <div>
                    <Link
                        to="/login"
                    >
                        Login
                    </Link>
                    <Link
                        to="/register"
                    >
                        Register
                    </Link>
                </div>
            )}
        </div>
    );
}

export default Home;
