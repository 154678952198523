import React from 'react';
import Container from 'react-bootstrap/Container';
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";

import Register from "./Components/Account/Register";
import Login from "./Components/Account/Login";
import {AuthProvider} from "./Contexts/AuthContext";
import Home from "./Components/Home";
import Profile from "./Components/Account/Profile";
import WithPrivateRoute from "./Utils/WithPrivateRoute";

function App() {
    return (
        <Container>
            <AuthProvider>
                <Router>
                    <Routes>
                        <Route path="/profile" element={
                            <WithPrivateRoute>
                                <Profile/>
                            </WithPrivateRoute>
                        }/>
                        <Route path="/register" element={<Register/>}/>
                        <Route path="/login" element={<Login/>}/>
                        <Route path="/" element={<Home/>}/>
                    </Routes>
                </Router>
            </AuthProvider>
        </Container>
    );
}

export default App;
