import React, {FormEvent, useState, useEffect} from 'react';
import { useAuth } from "../../Contexts/AuthContext";
import { useNavigate } from "react-router-dom";

import {Link} from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

function Register() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();
    const { currentUser, register } = useAuth();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (currentUser) {
            navigate("/");
        }
    }, [currentUser, navigate]);

    async function handleFormSubmit(e: FormEvent) {
        e.preventDefault();

        try {
            setLoading(true);
            await register(email, password);
            navigate("/profile");
        } catch (e) {
            alert("Failed to register");
        }

        setLoading(false);
    }

    return (
        <div>
            <h2>
                Register your account
            </h2>
            <Form onSubmit={handleFormSubmit}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control
                        type="email"
                        placeholder="Enter email"
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <Form.Text className="text-muted">
                        We'll never share your email with anyone else.
                    </Form.Text>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                        type="password"
                        placeholder="Password"
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </Form.Group>

                <Button
                    variant="primary"
                    type="submit"
                    disabled={loading}
                >
                    Register
                </Button>
            </Form>

            <Link
                to="/login"
            >
                Already have an account? Login
            </Link>
        </div>
    );
}

export default Register;
