import React, {FormEvent, useEffect, useState} from 'react';
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {Link, useNavigate} from "react-router-dom";
import {useAuth} from "../../Contexts/AuthContext";

function Login() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();
    const { currentUser, login } = useAuth();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (currentUser) {
            navigate("/");
        }
    }, [currentUser, navigate]);

    async function handleFormSubmit(e: FormEvent) {
        e.preventDefault();

        try {
            setLoading(true);
            await login(email, password);
            navigate("/profile");
        } catch (e) {
            alert("Failed to register");
        }

        setLoading(false);
    }

    return (
        <div className="login">
            <h2>
                Login to your account
            </h2>
            <Form onSubmit={handleFormSubmit}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control type="email" placeholder="Enter email" onChange={(e) => setEmail(e.target.value)}/>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)}/>
                </Form.Group>

                <Button
                    variant="primary"
                    type="submit"
                    disabled={loading}
                >
                    Login
                </Button>
            </Form>

            <Link
                to="/register"
            >
                Don't have an account? Register
            </Link>
        </div>
    );
}

export default Login;
