import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyB2Og9CAhWaBv9xzR_BKx7EY3qNxchXhq4",
    authDomain: "surfline-calendar.firebaseapp.com",
    databaseURL: "https://surfline-calendar-default-rtdb.firebaseio.com",
    projectId: "surfline-calendar",
    storageBucket: "surfline-calendar.appspot.com",
    messagingSenderId: "357938442671",
    appId: "1:357938442671:web:89e1d186ceb59dcadebf5a"
};

const app = initializeApp(firebaseConfig);

// gives us an auth instance
const auth = getAuth(app);

// in order to use this auth instance elsewhere
export default auth;