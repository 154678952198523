import { Navigate } from "react-router-dom";
import { useAuth } from "../Contexts/AuthContext";
import React from "react";

const WithPrivateRoute = ({ children }: {
    children: JSX.Element | null;
}) => {
    const { currentUser } = useAuth();

    // If there is a current user it will render the passed down component
    if (currentUser) {
        return children;
    }

    // Otherwise redirect to the login route
    return <Navigate to="/login" />;
};

export default WithPrivateRoute;