import React, {createContext, useContext, useEffect, useState} from "react";
import {createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut} from "firebase/auth";

import auth from "../firebase";
import {User, UserCredential} from "@firebase/auth";

interface AuthContextInterface {
    currentUser: User | null;
    login: (email: string, password: string) => Promise<UserCredential> | void;
    register: (email: string, password: string) => Promise<UserCredential> | void;
    logout: () => Promise<void> | void;
}

const AuthContext = createContext<AuthContextInterface>({
    currentUser: null,
    login: (email: string, password: string) => {},
    register: (email: string, password: string) => {},
    logout: () => {},
});

export function useAuth() {
    return useContext(AuthContext);
}

interface AuthProviderProp {
    children: React.ReactNode;
}

export function AuthProvider({ children }: AuthProviderProp) {
    const [currentUser, setCurrentUser] = useState<User | null>(null);
    const [loading, setLoading] = useState(true);

    function register(email: string, password: string) {
        return createUserWithEmailAndPassword(auth, email, password);
    }

    function login(email: string, password: string) {
        return signInWithEmailAndPassword(auth, email, password);
    }

    function logout() {
        return signOut(auth);
    }

    useEffect(() => {
        return auth.onAuthStateChanged((user: User | null) => {
            setCurrentUser(user);
            setLoading(false);
        });
    }, []);

    const value = {
        currentUser,
        login,
        register,
        logout
    };

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    );
}